import React from "react"
import Hero from "./Hero"
import Head from "../utils/Head"

function Thankyou({ description }) {
  return (
    <div>
      <Head title="Thank You" />
      <Hero
        btnText="CHECK OUT OUR PORTFOLIO"
        img="/thanku-bg.svg"
        header="Thank you for reaching out to us!"
        text={description}
      />
    </div>
  )
}

export default Thankyou
