import React from "react"
import Thankyou from "../components/ThankyouPage"
import Layout from "../components/Layout"
import { Helmet } from "react-helmet"
export default function ThankyouPage() {
 
  return (
    <Layout>
      <Helmet>
        <link rel="canonical" href="https://codefulcrum.com/thank-you" />
      </Helmet>
      <Thankyou
        description={
          "One of our consultants will be getting in touch with you shortly to discuss your needs and necessities, to understand how we can be helpful for your project and what is required from your side to move forward and define the next steps for future cooperation."
        }
      />
    
    </Layout>
  )
}
